<!-- 权限管理 -->
<template>
  <div class="rights">
    <el-card style="margin-top: 20px">
      <div class="input-with">
        <el-button type="primary" @click="addRole" :disabled="addBtnDisabled">新增</el-button>
      </div>
      <el-table
        v-loading="loading"
        :data="listData"
        style="width: 100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        @sort-change="sortChanged"
      >
        <el-table-column label="ID" align="center">
          <template v-slot="{ row }">
            <div>G000{{ row.id }}</div>
          </template>
        </el-table-column>
        <!-- userName -->
        <el-table-column label="角色名" prop="roleName" align="center"></el-table-column>
        <el-table-column label="角色描述" prop="description" align="center"></el-table-column>
        <el-table-column
          label="用户数量"
          prop="userCount"
          align="center"
          sortable
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template v-slot="{ row }">
            <span class="edit" @click="setRights(row.id)">权限管理</span>
            <span class="edit" @click="editclick(row.id)">编辑</span>
            <span class="frozen" @click="deleteBtn(row.id)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-card>

    <!-- 新增弹窗 -->
    <el-dialog
      :title="title"
      :visible.sync="roleDialogVisible"
      :before-close="handleClose"
      width="527px"
      :close-on-click-modal="false"
    >
      <el-form label-width="100px" :model="adminUser" :rules="adminrules" ref="form">
        <el-form-item label="角色名:" prop="roleName">
          <el-input v-model="adminUser.roleName" placeholder="请输入角色名" />
        </el-form-item>
        <el-form-item label="角色描述:" prop="description">
          <el-input
            v-model="adminUser.description"
            type="textarea"
            :rows="4"
            placeholder="请输入角色描述"
          />
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitForm" :disabled="submitBtnDisabled"
          >确 定</el-button
        >
        <el-button @click="handleClose">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 权限管理弹窗 -->
    <el-dialog
      title="权限管理"
      :visible.sync="rightsDialogVisible"
      :before-close="handleCloseAuth"
      width="527px"
      :close-on-click-modal="false"
    >
      <el-tree
        ref="tree"
        :data="rights"
        :props="defaultProps"
        @check="currentChecked"
        show-checkbox
        node-key="id"
        :default-checked-keys="roleInfo.currentNode"
        :default-expanded-keys="roleInfo.currentKey"
      ></el-tree>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitForm(1)" :disabled="submitBtnDisabled"
          >确 定</el-button
        >
        <el-button @click="handleCloseAuth">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  roleList,
  roleDetail,
  addRole,
  deleteRole,
  editRole,
  rightsListAll,
  rightsDetail
} from '@/api/system.js'

const defaultQueryInfo = Object.freeze({
  orders: 0,
  roleName: '',
  pageNum: 1,
  pageSize: 10
})
export default {
  name: 'Rights',
  data() {
    return {
      title: '', // 弹窗标题
      // 选中的权限
      selected: {
        node: [],
        expanded: []
      },
      // 按钮点击次数  用于防止多次点击
      btnCount: {
        setRightsBtn: 0
      },
      saveSetRightsBtnDisabled: false, // 禁用权限管理保存按钮
      addBtnDisabled: false, // 禁用新增按钮
      submitBtnDisabled: false, // 禁用保存按钮
      rights: [], // 权限列表
      defaultProps: {
        children: 'children',
        label: 'authName'
      },
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      roleDialogVisible: false, // 新增、编辑角色弹窗
      rightsDialogVisible: false, // 分配权限弹窗
      // 新增、编辑角色传参
      adminUser: {
        authIdList: [], // 权限ID
        description: '', // 角色描述
        id: 0, // 角色ID
        roleName: '' // 角色名
      },
      roleInfo: {
        currentKey: [], // 选中的权限
        currentNode: [] //  展开的节点
      }, // 角色信息
      listrole: [],
      // 角色表单校验规则
      adminrules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        description: [{ required: true, message: '请输入角色描述', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.search()
    this.rightsListAll()
  },
  methods: {
    async search() {
      this.loading = true
      const res = await roleList(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
        this.loading = false
      } else {
        this.$message.warning('获取角色信息失败!')
        this.loading = false
      }
    },
    // 获取所有权限列表
    async rightsListAll() {
      const res = await rightsListAll()
      if (res.resultCode === 200) {
        this.rights = res.data
      } else {
        this.$message.warning('获取权限信息失败!')
      }
    },
    // 打开新增角色弹窗
    addRole() {
      this.title = '新增角色'
      this.addBtnDisabled = true
      setTimeout(() => {
        this.addBtnDisabled = false
      }, 1000)
      this.roleDialogVisible = true
      this.adminUser = {
        authIdList: [],
        description: '',
        roleName: ''
      }
    },
    // 打开编辑角色弹窗
    editclick(id) {
      this.title = '编辑角色'
      this.btnCount.setRightsBtn++
      if (this.btnCount.setRightsBtn > 1) {
        return (this.btnCount.setRightsBtn = 0)
      } else {
        this.roleDetail(id, 2)
      }
      setTimeout(() => {
        this.btnCount.setRightsBtn = 0
      }, 2000)
    },
    // 打开权限管理弹窗
    setRights(id) {
      this.btnCount.setRightsBtn++
      if (this.btnCount.setRightsBtn > 1) {
        return (this.btnCount.setRightsBtn = 0)
      } else {
        this.roleDetail(id, 1)
      }
      setTimeout(() => {
        this.btnCount.setRightsBtn = 0
      }, 2000)
    },
    // 设置默认勾选选中的权限
    setCheckedKeys(keys) {
      this.$refs.tree.setCheckedKeys(keys)
    },
    // 获取角色详情
    async roleDetail(id, type) {
      this.roleInfo.currentKey = []
      this.roleInfo.currentNode = []
      const res = await roleDetail({ id })
      if (res.resultCode == 200) {
        this.adminUser = res.data
        Object.assign(this.roleInfo, res.data)
        // this.roleInfo = { ...this.roleInfo, ...res.data }
        this.adminUser.authIdList = []
        this.roleInfo.auths.forEach((item) => {
          this.adminUser.authIdList.push(item.id)
          this.roleInfo.currentKey.push(item.id)
          if (item.children.length) {
            item.children.forEach((el) => {
              this.roleInfo.currentKey.push(item.id)
              this.roleInfo.currentNode.push(el.id)
              this.adminUser.authIdList.push(el.id)
            })
          } else {
            this.roleInfo.currentNode.push(item.id)
          }
        })
        if (type === 1) {
          this.rightsDialogVisible = true
          this.$nextTick(() => {
            this.setCheckedKeys(this.roleInfo.currentNode)
          })
        } else if (type === 2) {
          this.roleDialogVisible = true
        }
      }
    },
    //保存按钮
    async submitForm(type) {
      let arr = []
      if (type === 1) {
        // 弹窗为分配权限弹窗时
        if (this.adminUser.authIdList.length === 0) {
          this.adminUser.authIdList = this.$refs.tree.getCheckedKeys()
        } else {
          this.adminUser.authIdList = this.adminUser.authIdList
            .concat(this.selected.node)
            .concat(this.selected.expanded)
        }
        // 数组去重
        this.adminUser.authIdList.forEach((item) => {
          if (!arr.includes(item)) {
            arr.push(item)
          }
        })
        const param = {
          authIdList: arr,
          description: this.adminUser.description,
          roleName: this.adminUser.roleName,
          id: this.adminUser.id
        }
        this.setOffsubmitBtn()
        const res = await editRole(param)
        if (res.resultCode === 200) {
          this.$message.success('设置权限成功!')
          this.handleCloseAuth()
          this.search()
        } else {
          this.$message.warning('设置权限失败!')
        }
      } else {
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            this.setOffsubmitBtn()
            const fc = this.adminUser.id ? editRole : addRole
            const txt = this.adminUser.id ? '编辑' : '新增'
            const res = await fc(this.adminUser)
            console.log(res)
            if (res.resultCode === 200) {
              this.$message.success(txt + '成功!')
              this.handleClose()
              this.search()
            } else {
              this.$message.warning(txt + '失败!')
            }
          }
        })
      }
    },
    // 禁用保存按钮
    setOffsubmitBtn() {
      this.submitBtnDisabled = true
      setTimeout(() => {
        this.submitBtnDisabled = false
      }, 2000)
    },
    // 新增、编辑弹窗关闭
    handleClose() {
      this.roleDialogVisible = false
      this.adminUser = {
        authIdList: [], // 权限ID
        description: '', // 角色描述
        id: 0, // 角色ID
        roleName: '' // 角色名
      }
    },
    // 分配权限弹窗关闭
    handleCloseAuth() {
      this.adminUser = {
        authIdList: [], // 权限ID
        description: '', // 角色描述
        id: 0, // 角色ID
        roleName: '' // 角色名
      }
      this.selected.node = []
      this.selected.expanded = []
      this.rightsDialogVisible = false
    },
    //选择权限
    currentChecked(nodeObj, SelectedObj) {
      if (nodeObj.parentId) {
        this.selected.node.push(nodeObj.parentId)
      }
      this.selected.node = SelectedObj.checkedKeys
      this.selected.expanded = SelectedObj.halfCheckedKeys
    },
    // 删除角色
    deleteBtn(id) {
      this.$confirm('确认是否删除，删除后不可撤回')
        .then(async () => {
          const res = await deleteRole({ id })
          if (res.resultCode == 200) {
            this.$message.success('删除成功!')
            this.search()
          } else {
            this.$message.warning(res.message)
          }
        })
        .catch(() => {})
    },
    // 排序
    sortChanged(val) {
      // console.log(val)
      if (val.order === 'ascending') {
        this.queryInfo.orders = 1
        this.search()
      } else {
        this.queryInfo.orders = 2
        this.search()
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.input-with {
  width: 100%;
}

.in_table {
  width: 100%;
  height: 70vh;
  overflow-y: auto;
  margin-top: 20px;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
}
.edit {
  padding-left: 20px;
  color: #4e93fb;
  cursor: pointer;
}
.frozen {
  padding-left: 20px;
  color: #fd5469;
  cursor: pointer;
}

.new_page {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
</style>
